import React from 'react';
import Navbar from "../component/Navbar";
import LiontronCloudLogo from "./logo-liontroncloud.png"

const Logout = () => {
    return (
        <header>
            <div className='wrapper'>
                <img src={LiontronCloudLogo} alt="LiontronCloud Logo" />
                <Navbar />
            </div>
        </header>
    );
};

export default Logout;