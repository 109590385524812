import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import './Home.css';
import ProgressBar from "./ProgressBar";

const Home = () => {
  const history = useHistory();
  axios.defaults.withCredentials = true;

  const [collectors, setCollectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState({}); // Zustand für das Öffnen von Collapse-Items

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    const fetchMultiAppData = async () => {
      try {
        const responseMulti = await axios.get(`${apiBaseUrl}/api/getDataFromMultiApp`, {
          params: {
            token: 'zy98UEPjK5wOiHib7qZw6i32C1eDyICrjVFEtMIf',
          },
        });

        // Extrahiere die Daten und gruppiere sie nach Collectoren
        const collectorsData = parseCollectors(responseMulti.data);
        setCollectors(collectorsData);
      } catch (err) {
        console.error("Error fetching multi-app data:", err);
        setError("Error fetching battery data.");
      }
    };

    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/profile/profile`);
        if (response.data.login) {
          // Handle successful login
        } else {
          history.push("/login");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError("Error fetching profile.");
        history.push("/");
      } finally {
        setLoading(false);
      }
    };

    // Funktion zum Parsen der Collector-Daten
    const parseCollectors = (data) => {
      const collectors = {};

      if (data && data.data) {
        data.data.forEach((entry) => {
          const collector = JSON.parse(entry.collector);
          const batts = JSON.parse(entry.batts);

          collectors[collector.mac] = {
            name: collector.name,
            batteries: Object.keys(batts).map(key => {
              const battery = batts[key];
              const hexString = battery.adv;
              const batteryData = parseBuffer(hexString);
              batteryData.batteryMac = key;
              batteryData.name = battery.name;
              return batteryData;
            }),
          };
        });
      }

      return collectors;
    };

    // Funktion zum Parsen der Hex-Zeichenkette
    const parseBuffer = (hexString) => {
      const hexStringToUint8Array = (hexString) => {
        if (hexString.length % 2 !== 0) {
          throw new Error('Hex string length must be even');
        }
        const byteArray = new Uint8Array(hexString.length / 2);
        for (let i = 0; i < byteArray.length; i++) {
          byteArray[i] = parseInt(hexString.substr(i * 2, 2), 16);
        }
        return byteArray;
      };

      const buffer = hexStringToUint8Array(hexString);
      const battery = {};

      let pro = (buffer[4] << 8) | buffer[5];
      battery.protection = pro;

      let fet = buffer[7] & 0b00000011;
      battery.fets = fet;

      let v = (buffer[6] << 8) | buffer[7];
      v >>= 3;
      battery.packV = v / 100.0;

      let a = (buffer[8] << 8) | buffer[9];
      console.log(a);
      a = a - 32767;
      battery.packA = a / 100.0;

      let r = (buffer[10] << 8) | buffer[11];
      battery.remainCap = r / 100.0;

      let c = (buffer[12] << 8) | buffer[13];
      battery.cap = c / 100.0;

      let soc = (buffer[14] & 0b11111110) >> 1;
      battery.soc = soc;

      let ntc = (buffer[15] & 0b11111000) >> 3;
      ntc |= (buffer[14] & 0b00000001) << 5;
      ntc = (ntc * 2) - 30;
      battery.ntc1 = ntc;
      battery.ntcCount = 1;

      let cycles = (buffer[16] << 6) | (buffer[17] >> 2);
      battery.cycles = cycles;

      let cellcount = (buffer[15] & 0b00000111) + 1;
      battery.cellCount = cellcount;

      for (let i = 1; i <= 4; i++) {
        let cellV = buffer[18 + i];
        cellV = (cellV * 10) + 1500;
        battery[`cell${i}`] = cellV / 1000.0;
      }

      if (cellcount > 4) {
        for (let i = 5; i <= 8; i++) {
          let cellV = buffer[18 + i];
          cellV = (cellV * 10) + 1500;
          battery[`cell${i}`] = cellV / 1000.0;
        }
      }
      return battery;
    };

    // Initialer Datenabruf
    fetchProfile();
    fetchMultiAppData();

    // Intervall für regelmäßige Datenabrufe
    const intervalId = setInterval(() => {
      fetchMultiAppData();
    }, 15000); // 5000 ms = 5 Sekunden

    // Bereinigen des Intervals bei Unmount
    return () => clearInterval(intervalId);
  }, [history, apiBaseUrl, domain]);

  useEffect(() => {
    const animateProgressBars = () => {
      document.querySelectorAll('.progress').forEach(function (progress) {
        const bar = progress.querySelector('.bar');
        const val = progress.querySelector('span');
        const perc = parseInt(val.textContent, 10);

        const start = 0;
        const duration = 3000;
        const startTime = performance.now();

        function animate(time) {
          const progressTime = Math.min((time - startTime) / duration, 1);
          const p = start + (perc - start) * progressTime;

          bar.style.transform = "rotate(" + (45 + p * 1.8) + "deg)";
          val.textContent = Math.floor(p);

          if (progressTime < 1) {
            requestAnimationFrame(animate);
          }
        }

        requestAnimationFrame(animate);
      });
    };

    if (collectors.length > 0) {
      animateProgressBars();
    }
  }, [collectors]);

  const handleShowReport = (batteryId) => {
    history.push(`/report/${batteryId}`);
  };

  const handleToggle = (mac) => {
    setOpen(prev => ({ ...prev, [mac]: !prev[mac] }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="home">
      <h2>Home</h2>
      <p>Welcome to the Liontron Cloud!</p>
      <div className="collectors">
        {Object.keys(collectors).length > 0 ? (
          Object.keys(collectors).map((mac, index) => (
            <div key={index} className="collector">
              <button className="collapsible" onClick={() => handleToggle(mac)}>
                {collectors[mac].name} (MAC: {mac})
              </button>
              <div className={`content ${open[mac] ? 'active' : ''}`}>
                {collectors[mac].batteries.length > 0 ? (
                  collectors[mac].batteries.map((battery, i) => (
                    <div key={i} className="battery">
                      <div className="batterie-headline">Battery: {battery.name}</div>
                      <div className="batterie-body">
                        <div className="card">
                          <div className="card-headline">
                            Charging status
                          </div>
                          <div className="card-body">
                            <ProgressBar percentage={battery.soc} />
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Capacity
                          </div>
                          <div className="card-body">
                            <div className="capacity-container">
                              <div className="capacity-value">
                                {battery.cap} Ah
                              </div>
                              <div className="vertical-progress">
                                <div
                                  className="progress-bar"
                                  style={{ height: `${(battery.remainCap / battery.cap) * 100}%` }}
                                />
                              </div>
                              <div className="progress-text" style={{ bottom: `${((battery.remainCap / battery.cap) * 100) - 10}%` }}>
                                {battery.remainCap} Ah
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Temperature
                          </div>
                          <div className="card-body">
                            {battery.ntc1} °C
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Number of Sensors
                          </div>
                          <div className="card-body">
                            {battery.ntcCount}
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Current
                          </div>
                          <div className="card-body">
                            {battery.packA} A
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Error
                          </div>
                          <div className="card-body text-small">
                            {battery.protection == 0 ? 'no error' : 'error'}
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            FETs
                          </div>
                          <div className="card-body text-small"
                            dangerouslySetInnerHTML={{
                              __html: (battery.fets === 2)
                                ? 'Discharge allowed<br />Charge denied'
                                : (battery.fets === 1)
                                  ? 'Discharge denied<br />Charge allowed'
                                  : (battery.fets === 0)
                                    ? 'Discharge denied<br />Charge denied'
                                    : 'Discharge allowed<br />Charge allowed'
                            }}
                          />
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Cycles
                          </div>
                          <div className="card-body">
                            {battery.cycles}
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Voltage
                          </div>
                          <div className="card-body">
                            {battery.packV} V
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-headline">
                            Cell Count
                          </div>
                          <div className="card-body">
                            {battery.cellCount}
                          </div>
                        </div>
                        {[...Array(battery.cellCount)].map((_, j) => (

                          <div className="card">
                            <div className="card-headline">
                              Cell {j + 1} Voltage
                            </div>
                            <div className="card-body">
                              {battery[`cell${j + 1}`]} V
                            </div>
                          </div>
                        ))}
                        <div className="card">
                          <a className="card-link" href="" onClick={() => handleShowReport(battery.batteryMac)}>
                            <div className="card-body text-small">
                              Show<br />report
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No battery data available.</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No collectors data available.</p>
        )}
      </div>
    </div>
  );
};

export default Home;
