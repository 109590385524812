import React, { useEffect, useState } from "react";

const ProgressBar = ({ percentage }) => {
    const [currentPercentage, setCurrentPercentage] = useState(0);

    useEffect(() => {
        let start = currentPercentage;
        const duration = 3000; // 3 Sekunden
        const startTime = performance.now();

        const animate = (time) => {
            const progressTime = Math.min((time - startTime) / duration, 1);
            const newPercentage = start + (percentage - start) * progressTime;

            setCurrentPercentage(Math.floor(newPercentage)); // Runden auf eine Ganzzahl

            if (progressTime < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [percentage]); // Bei Änderung von "percentage" erneut animieren

    return (
        <div className="progress">
            <div className="barOverflow">
                <div
                    className="bar"
                    style={{ transform: `rotate(${45 + currentPercentage * 1.8}deg)` }}
                />
            </div>
            <div className="bar-percent-text">
                <span className="bar-text">
                    <strong>{currentPercentage}</strong>
                </span>
                %
            </div>
        </div>
    );
};

export default ProgressBar;
