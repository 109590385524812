import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [user, setUser] = useState({ username: "", email: "", password: "" });
  const [msg, setMsg] = useState("");
  const history = useHistory();

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/auth/register`, user);
      setMsg(response.data.msg);
      if (response.data.success) {
        history.push("/login");
      }
    } catch (error) {
      setMsg("Registration failed: " + error.message);
    }
  };

  return (
    <div className="register">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" name="username" value={user.username} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={user.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" name="password" value={user.password} onChange={handleChange} required />
        </div>
        <button type="submit">Register</button>
      </form>
      {msg && <p className="info">{msg}</p>}
    </div>
  );
};

export default Register;
