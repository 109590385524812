// SocketContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    const connectSocket = (url) => {
        if (socket) {
            console.log('WebSocket connection already exists.');
            return;
        }

        const newSocket = io(url, { secure: true, reconnectionAttempts: 5 });

        newSocket.on('connect', () => {
            console.log('WebSocket connected');
            setSocket(newSocket);
        });

        newSocket.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
            setSocket(null);
        });

        newSocket.on('disconnect', () => {
            console.log('WebSocket disconnected');
            setSocket(null);
        });
    };

    const disconnectSocket = () => {
        if (socket) {
            socket.disconnect();
            setSocket(null);
            console.log('WebSocket disconnected');
        }
    };

    useEffect(() => {
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [socket]);

    return (
        <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);
