import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useSocket } from '../context/SocketContext';
import './Home.css';

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

const ConfigDevice = () => {
    const query = useQuery();
    const mac = query.get('mac');
    const supportCode = query.get('supportCode');
    const history = useHistory();
    const { socket } = useSocket();

    const domain = window.location.hostname;

    useEffect(() => {
        if (socket) {
            const enterFactoryMode = {
                type: "deviceMessages",
                data: {
                    supportCode: supportCode,
                    devices: {
                        [mac]: "DD 5A 00 02 56 78 FF 30 77"
                    }
                }
            };
            socket.emit("message", enterFactoryMode);

            const getChargeLoadReleasedTemperatureInformation = {
                type: "deviceMessages",
                data: {
                    supportCode: supportCode,
                    devices: {
                        [mac]: "DD A5 1B 00 FF E5 77"
                    }
                }
            };
            socket.emit("message", getChargeLoadReleasedTemperatureInformation);

            const dischargedHighTemperatureInformation = {
                type: "deviceMessages",
                data: {
                    supportCode: supportCode,
                    devices: {
                        [mac]: "DD A5 1C 00 FF E4 77"
                    }
                }
            };
            socket.emit("message", dischargedHighTemperatureInformation);

            const closeFactoryMode = {
                type: "deviceMessages",
                data: {
                    supportCode: supportCode,
                    devices: {
                        [mac]: "DD 5A 01 02 00 00 FF FD 77"
                    }
                }
            };
            socket.emit("message", closeFactoryMode);

            const handleDeviceSelection = (message) => {
                console.log("WebSocket message received:", message);
            }

            socket.on('message', handleDeviceSelection);

            return () => {
                socket.off('message', handleDeviceSelection);
            };
        };

    }, [socket]);



    return (
        <div className="home">
            <div className="config-container">
                <div className="battery">
                    <div className="batterie-headline">
                        <div>Gerätedetails: <strong>MAC-Adresse:</strong> {mac} <strong>SupportCode:</strong> {supportCode}</div>
                        <a href={`/showDevice?mac=${mac}&supportCode=${supportCode}`}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(`/showDevice?mac=${mac}&supportCode=${supportCode}`);
                            }}>
                            zurück
                        </a>
                    </div>
                    <div className="batterie-body">
                        <div className="box">
                            <fieldset>
                                <legend><span>Basis Einstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Bluetooth Name</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Serien Nummer</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Barcode</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Batterie Model</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Batterie Hersteller</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>BMS Versions Nummer</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>BMS Model</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Produktionsdatum</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>BMS Adresse</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                            <fieldset>
                                <legend><span>Sicherheits Parameter</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Einzelner Überspannungsschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Einzelne Überspannungswiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Einzelne Überspannungsverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Einzelner sekundärer Druckschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Einzelne sekundäre Druckrückgewinnung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Einzelne Sekundärdruckverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Umfassender Überspannungsschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Gesamtüberspannungswiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Gesamtüberspannungsverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Gesamte Sekundärdruckwiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Gesamte Sekundärdruckwiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Gesamtverzögerung des Sekundärdrucks</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Hardware-Überspannung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Hardware-Unterspannung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group"></div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                        </div>
                        <div className="box">
                            <fieldset>
                                <legend><span>Aktuelle Einstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Ladeüberstromschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Ladeüberstromverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Verzögerung der Wiederherstellung nach Ladeüberstrom</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Entladeüberstromschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladeüberstromverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Wiederherstellungsverzögerung bei Entladungsüberstrom</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Sekundärer Überstromschutzwert*2</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Sekundärer Überstromschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Sekundäre Überstrom-Wiederherstellungsverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Kurzschlussschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Kurzschlussschutzverzögerung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Wiederherstellungsverzögerung des Kurzschlussschutzes</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                            <fieldset>
                                <legend><span>Temperatureinstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Lade-Hochtemperaturschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Lade-Hochtemperaturwiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Ladeverzögerung bei hoher Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Lade-Niedrigtemperaturschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Lade-Niedrigtemperaturwiederherstellung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Ladeverzögerung bei niedriger Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Entladungs-Hochtemperaturschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladungsrückgewinnung bei hoher Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladungsverzögerung bei hoher Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Entladungs-Tieftemperaturschutz</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladungsrückgewinnung bei niedriger Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladungsverzögerung bei niedriger Temperatur</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                        </div>
                        <div className="box">
                            <fieldset>
                                <legend><span>Ausgleichseinstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Ausgeglichene Spannung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Waagengenauigkeit</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Ausgewogene Methode</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Balance-Schalter</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">

                                        </div>
                                        <div className="input-group">

                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                            <fieldset>
                                <legend><span>Kondensatorspannung</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>10%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>20%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>30%</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>40%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>50%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>60%</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>70%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>80%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>90%</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>100%</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Volle Spannung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Entladespannung</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                        </div>
                        <div className="box">
                            <fieldset>
                                <legend><span>Anfangseinstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Nennkapazität</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Zykluskapazität</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                            <fieldset>
                                <legend><span>Funktionseinstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Schalterfunktion</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Lasterkennung</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Ausgleichsfunktion</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Ausgewogener Weg</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>LED</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Anzahl der LEDs</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>RTC</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>FCC-Einschränkungen</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Charge Handshake aktivieren</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>GPS</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Summer aktivieren</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_1</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_2</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_3</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_4</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_5</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_6</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_7</label>
                                            <input type="text"></input>
                                        </div>
                                    </div>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Temperaturkontrolle_8</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                        </div>
                                        <div className="input-group">
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                        </div>
                        <div className="box">
                            <fieldset>
                                <legend><span>Systemeinstellungen</span><span><a href="#">&#x21bb;</a></span></legend>
                                <form>
                                    <div className="tripple-input">
                                        <div className="input-group">
                                            <label>Anzahl der Strings</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                            <label>Erkennungswiderstand</label>
                                            <input type="text"></input>
                                        </div>
                                        <div className="input-group">
                                        </div>
                                    </div>
                                    <button type="submit">konfigurieren</button>
                                </form>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ConfigDevice;
