import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import './Home.css';

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

const ShowDevice = () => {
    const query = useQuery();
    const mac = query.get('mac');
    const supportCode = query.get('supportCode');
    const history = useHistory();
    axios.defaults.withCredentials = true;

    const domain = window.location.hostname;
    const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

    const [parsedData, setParsedData] = useState([]);

    // Funktion zum Zusammenführen der Daten
    const mergeData = (hexStrings) => {
        let mergedString = '';
        let inDataSection = false;

        hexStrings.forEach(hexString => {
            hexString = hexString.replace(/ /g, ''); // Entfernen der Leerzeichen

            if (hexString.startsWith('DD')) {
                inDataSection = true;
                mergedString += hexString;
            } else if (inDataSection) {
                mergedString += hexString;
                if (hexString.endsWith('77')) {
                    inDataSection = false;
                }
            }
        });

        if (mergedString.length < 72) {
            throw new Error("Zusammengeführte Daten sind zu kurz");
        }

        return mergedString;
    };

    // Funktion zum Parsen der zusammengeführten Daten
    const parseData = (data) => {
        if (data.length % 2 !== 0) {
            throw new Error("Hex-Daten haben eine ungerade Länge");
        }

        const buffer = new ArrayBuffer(data.length / 2);
        const view = new DataView(buffer);

        for (let i = 0; i < data.length; i += 2) {
            view.setUint8(i / 2, parseInt(data.slice(i, i + 2), 16));
        }

        if (buffer.byteLength < 36) {
            throw new Error("Daten sind zu kurz, um alle benötigten Felder zu parsen");
        }

        return {
            start: data.slice(0, 2),
            namingCode: view.getUint8(1),
            statusCode: view.getUint8(2),
            dataLength: view.getUint8(3),
            totalVoltage: (view.getUint16(4, false) / 100) + ' V',
            totalCurrent: ((view.getUint16(6, false) & 0x8000) ? (view.getUint16(6, false) - 0x10000) : view.getUint16(6, false)) / 10,
            remainingCapacity: (view.getUint16(8, false) / 100.0) + ' Ah',
            nominalCapacity: (view.getUint16(10, false) / 100.0) + ' Ah',
            cycles: view.getUint16(12, false),
            productionDate: view.getUint16(14, false),
            balancedLow: view.getUint16(16, false),
            balancedHigh: view.getUint16(18, false),
            protectionStatus: view.getUint16(20, false),
            softwareVersion: view.getUint8(22),
            remainingCapacityPercentage: view.getUint8(23) + ' %',
            dontState: view.getUint8(24),
            numberOfBatteryStrings: view.getUint8(25),
            numberOfTemperatureProbes: view.getUint8(26),
            firstTemperature: ((view.getUint16(27, false) - 2731) / 10) + ' °C',
            secondTemperature: ((view.getUint16(29, false) - 2731) / 10) + ' °C',
            thirdTemperature: ((view.getUint16(31, false) - 2731) / 10) + ' °C',
            fourthTemperature: ((view.getUint16(33, false) - 2731) / 10) + ' °C',
            checkCode: view.getUint16(35, false),
            end: data.slice(38)
        };
    };


    const fetchData = async () => {
        console.log('data refresh');
        try {
            const response = await axios.get(`${apiBaseUrl}/api/deviceData`, {
                params: {
                    supportCode
                },
                withCredentials: true
            });

            // Sicherstellen, dass response.data.data ein Array ist
            const data = response.data.data;

            if (Array.isArray(data)) {
                // Die Hex-Daten für die angegebene MAC-Adresse filtern
                const deviceData = data
                    .filter(item => item.data && JSON.parse(item.data).devices && JSON.parse(item.data).devices[mac])
                    .map(item => JSON.parse(item.data).devices[mac][0]);

                // Zusammenführen der Daten
                const mergedData = mergeData(deviceData);

                // Parsen der zusammengeführten Daten
                const parsed = parseData(mergedData);
                setParsedData(parsed);
            } else {
                history.push("/");
            }
        } catch (error) {
            console.error("API request error:", error);
            history.push("/");
        }
    };

    let batterieData = JSON.parse(JSON.stringify(parsedData, null, 2));

    useEffect(() => {
        fetchData(); // Initialer Datenabruf

        // Intervall zum regelmäßigen Abrufen der Daten
        const intervalId = setInterval(fetchData, 5000);

        // Bereinigen des Intervals bei Unmount
        return () => clearInterval(intervalId);
    }, [history, apiBaseUrl, mac, supportCode]);

    return (
        <div className="home">
            <div className="report-container">
                <div className="battery">
                    <div className="batterie-headline">
                        <div>Gerätedetails: <strong>MAC-Adresse:</strong> {mac} <strong>SupportCode:</strong> {supportCode}</div>
                        <div>
                            <a href={`/configDevice?mac=${mac}&supportCode=${supportCode}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push(`/configDevice?mac=${mac}&supportCode=${supportCode}`);
                                }}>
                                Konfigurieren
                            </a>
                        </div>
                    </div>
                    <div className="batterie-body">
                        {/* Überprüfen, ob parsedData nicht leer ist */}
                        {parsedData && Object.keys(parsedData).length > 0 ? (
                            // Mit Object.entries() die Schlüssel und Werte des parsedData-Objekts durchlaufen
                            Object.entries(parsedData).map(([key, value], index) => (
                                (key === 'end' || key === 'start' || key === 'checkCode') ? '' : (
                                    <div className="card" key={index}>
                                        <div className="card-headline">
                                            {key}
                                        </div>
                                        <div className="card-body">
                                            {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                                        </div>
                                    </div>
                                )
                            ))
                        ) : (
                            <p>Keine Daten verfügbar</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ShowDevice;
