import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Logout from "../component/Logout"; // Pfad anpassen

const Navbar = () => {
  const history = useHistory();
  axios.defaults.withCredentials = true;
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/profile/profile`);
        if (response.data.login) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        setIsLoggedIn(false);
        history.push("/");
      }
    };
    fetchProfile();
  }, [history, setIsLoggedIn, apiBaseUrl]);

  return (
    <>
      {domain === 'remote.liontron.com' && (
        <nav>
          <ul>
            {!isLoggedIn ? (
              <>
                <li>
                  <NavLink to="/register">Registration</NavLink>
                </li>
                <li>
                  <NavLink to="/">Login</NavLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/profile">Profile</NavLink>
                </li>
                <li>
                  <Logout />
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
      {domain === 'cloud.liontron.com' && (
        <nav>
          <ul>
            {!isLoggedIn ? (
              <>
                <li>
                  <NavLink to="/register">Registration</NavLink>
                </li>
                <li>
                  <NavLink to="/">Login</NavLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </li>
                <li>
                  <Logout />
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Navbar;
