import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ProfileImageUpload from './ProfileImageUpload';

const Profile = () => {
  const [jobPosition, setJobPosition] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();
  axios.defaults.withCredentials = true;

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/profile/profile`);
        if (response.data.login) {
          setJobPosition(response.data.user.jobPosition);
          setFirstname(response.data.user.firstname);
          setLastname(response.data.user.lastname);
          setUsername(response.data.user.username);
          setEmail(response.data.user.email);
        } else {
          history.push("/");
        }
      } catch (error) {
        history.push("/");
      }
    };
    fetchProfile();
  }, [history, apiBaseUrl]);

  return (
    <div className="profile">
      <h2>Profile</h2>
      <ProfileImageUpload />
      <p>Job Position: {jobPosition}</p>
      <p>Firstname: {firstname}</p>
      <p>Lastname: {lastname}</p>
      <p>Username: {username}</p>
      <p>Email: {email}</p>
    </div>
  );
};

export default Profile;
