// Home.jsx
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SupportCodeSearch from "./SupportCodeSearch";
import { useSocket } from "../context/SocketContext"; // Importiere den SocketContext

const Home = () => {
  const history = useHistory();
  axios.defaults.withCredentials = true;
  const [message, setMessage] = useState("");
  const { socket } = useSocket(); // Verwende die WebSocket-Verbindung aus dem Kontext

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/profile/profile`);
        if (response.data.login) {
          // Beispiel-Daten zum Senden über WebSocket nach erfolgreicher Profilabfrage
          const dataUser = JSON.stringify({ type: "profile", data: { username: response.data.user.email, endpoint: domain } });
          if (socket) {
            socket.emit("message", dataUser);
          }
        } else {
          history.push("/login");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        history.push("/");
      }
    };

    const handleSocketMessage = (data) => {
      console.log(data);
      setMessage(data.message); // Beispiel: Setze die empfangene Nachricht in den State
      // Hier kannst du je nach Nachrichteninhalt entsprechend reagieren
    };

    // Event Listener für eingehende Nachrichten hinzufügen
    if (socket) {
      socket.on("message", handleSocketMessage);
    }

    // Rufe fetchProfile auf, wenn die Komponente montiert wird
    fetchProfile();

    // Cleanup: Entferne den Event Listener beim Unmount der Komponente
    return () => {
      if (socket) {
        socket.off("message", handleSocketMessage);
      }
    };
  }, [history, socket, apiBaseUrl, domain]);

  return (
    <div className="home">
      <h2>Home</h2>
      <p>Welcome to the Liontron Cloud!</p>
      <SupportCodeSearch />
      {message && <p>Last WebSocket message: {message}</p>}
    </div>
  );
};

export default Home;
