import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useSocket } from '../context/SocketContext';

const SupportCodeSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [supportCodeDetails, setSupportCodeDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [jobPosition, setJobPosition] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");

    const [devices, setDevices] = useState(null);

    const history = useHistory();
    axios.defaults.withCredentials = true;
    const { socket } = useSocket();

    const domain = window.location.hostname;
    const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/profile/profile`);
                if (response.data.login) {
                    setJobPosition(response.data.user.jobPosition);
                    setFirstname(response.data.user.firstname);
                    setLastname(response.data.user.lastname);
                    setProfileImageUrl(apiBaseUrl + response.data.user.profileImageUrl);
                }
            } catch (error) {
                console.error("Error fetching profile:", error);
                history.push("/");
            }
        };

        fetchProfile();
    }, [history, apiBaseUrl]);

    useEffect(() => {
        if (socket) {
            const handleDeviceSelection = (message) => {
                console.log("WebSocket message received:", message);
                if (message.type === 'deviceSelection' && message.data.supportCode === searchTerm) {
                    console.log("Device selection message matches support code:", message.data.devices);
                    setDevices(message.data.devices);

                    // Send the deviceMessages message in the required format
                    const deviceMessagesData = {
                        type: "deviceMessages",
                        data: {
                            supportCode: message.data.supportCode,
                            devices: Object.fromEntries(
                                Object.keys(message.data.devices).map(mac => [mac, "DD A5 03 00 FF FD 77"])
                            )
                        }
                    };
                    console.log("Sending deviceMessages:", deviceMessagesData);
                    socket.emit("message", deviceMessagesData);
                }
            };

            socket.on('message', handleDeviceSelection);

            return () => {
                socket.off('message', handleDeviceSelection);
            };
        }
    }, [socket, searchTerm]);

    const handleSearchChange = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.length === 11) {
            setLoading(true);
            try {
                const response = await axios.get(`${apiBaseUrl}/api/getSupportCodeList`);
                const supportCodes = response.data.supportCodes;
                const foundCode = supportCodes.find(code => code === value);
                if (foundCode) {
                    setSupportCodeDetails(foundCode);
                    handleSupportCodeSend(foundCode);
                } else {
                    setSupportCodeDetails(null);
                    setError('Support-Code nicht gefunden');
                }
            } catch (err) {
                setError('Fehler beim Laden der Support-Codes');
            } finally {
                setLoading(false);
            }
        } else {
            setSupportCodeDetails(null);
            setError(null);
        }
    };

    const handleSupportCodeSend = (supportCode) => {
        if (socket && socket.connected) {
            console.log("Sending supportMember message with profile image URL:", profileImageUrl);
            const data = {
                type: "supportMember",
                data: {
                    supportCode: supportCode,
                    supportMemberId: 1,
                    firstName: firstname,
                    lastName: lastname,
                    jobTitle: jobPosition,
                    profileImage: profileImageUrl
                }
            };
            console.log("Emitting message:", data);
            socket.emit("message", data);
        } else {
            console.error('WebSocket ist nicht verbunden');
        }
    };

    return (
        <>
            <input
                className='codeSearch'
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Support-Code eingeben"
                autoComplete="off"
            />
            {loading && <div>Lade...</div>}
            {error && <div>{error}</div>}
            {supportCodeDetails && (
                <div>
                    <p>{supportCodeDetails}</p>
                </div>
            )}
            {devices && (
                <div>
                    <h3>Gefundene Geräte:</h3>
                    <ul>
                        {Object.entries(devices).map(([mac, serial]) => (
                            <li key={mac}>
                                <a href={`/showDevice?mac=${mac}&supportCode=${searchTerm}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(`/showDevice?mac=${mac}&supportCode=${searchTerm}`);
                                    }}
                                >
                                    {mac}: {Array.isArray(serial) ? serial.join(', ') : serial}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default SupportCodeSearch;
