// Logout.jsx
import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useSocket } from '../context/SocketContext';

const Logout = () => {
  const history = useHistory();
  const { setIsLoggedIn } = useAuth();
  const { disconnectSocket } = useSocket();

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  const handleLogout = async () => {
    try {
      await axios.post(`${apiBaseUrl}/auth/logout`);
      disconnectSocket();
      setIsLoggedIn(false);
      history.push('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <a href="#" onClick={handleLogout}>
      Logout
    </a>
  );
};

export default Logout;
