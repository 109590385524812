import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'; // Importiere alles, was benötigt wird
import axios from "axios";
import './Home.css';
import { useParams } from "react-router-dom";

// Registriere alle benötigten Komponenten von Chart.js
Chart.register(...registerables);

const BatteryReportPage = () => {
    const { batteryId } = useParams(); // batteryId kommt von der URL
    const [reportData, setReportData] = useState({
        dates: [],
        temperature: [],
        capacity: [],
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Funktion, um die Daten auf 10-Minuten-Abstände zu filtern
    const filterDataByTime = (data) => {
        const filteredData = [];
        let lastTimestamp = null;

        data.forEach((entry) => {
            const currentTimestamp = new Date(entry.createdAt).getTime();

            if (!lastTimestamp || currentTimestamp - lastTimestamp >= 1 * 60 * 1000) {
                filteredData.push(entry);
                lastTimestamp = currentTimestamp;
            }
        });

        return filteredData;
    };

    useEffect(() => {
        const parseBuffer = (hexString) => {
            const hexStringToUint8Array = (hexString) => {
                if (hexString.length % 2 !== 0) {
                    throw new Error('Hex string length must be even');
                }
                const byteArray = new Uint8Array(hexString.length / 2);
                for (let i = 0; i < byteArray.length; i++) {
                    byteArray[i] = parseInt(hexString.substr(i * 2, 2), 16);
                }
                return byteArray;
            };

            const buffer = hexStringToUint8Array(hexString);
            const battery = {};

            let pro = (buffer[4] << 8) | buffer[5];
            battery.protection = pro;

            let fet = buffer[7] & 0b00000011;
            battery.fets = fet;

            let v = (buffer[6] << 8) | buffer[7];
            v >>= 3;
            battery.packV = v / 100.0;

            let a = (buffer[8] << 8) | buffer[9];
            console.log(a);
            a = a - 32767;
            battery.packA = a / 100.0;

            let r = (buffer[10] << 8) | buffer[11];
            battery.remainCap = r / 100.0;

            let c = (buffer[12] << 8) | buffer[13];
            battery.cap = c / 100.0;

            let soc = (buffer[14] & 0b11111110) >> 1;
            battery.soc = soc;

            let ntc = (buffer[15] & 0b11111000) >> 3;
            ntc |= (buffer[14] & 0b00000001) << 5;
            ntc = (ntc * 2) - 30;
            battery.ntc1 = ntc;
            battery.ntcCount = 1;

            let cycles = (buffer[16] << 6) | (buffer[17] >> 2);
            battery.cycles = cycles;

            let cellcount = (buffer[15] & 0b00000111) + 1;
            battery.cellCount = cellcount;

            for (let i = 1; i <= 4; i++) {
                let cellV = buffer[18 + i];
                cellV = (cellV * 10) + 1500;
                battery[`cell${i}`] = cellV / 1000.0;
            }

            if (cellcount > 4) {
                for (let i = 5; i <= 8; i++) {
                    let cellV = buffer[18 + i];
                    cellV = (cellV * 10) + 1500;
                    battery[`cell${i}`] = cellV / 1000.0;
                }
            }
            return battery;
        };

        const fetchReportData = async () => {
            try {
                const response = await axios.get('/api/getBatteryReport', {
                    params: {
                        token: 'zy98UEPjK5wOiHib7qZw6i32C1eDyICrjVFEtMIf',
                        mac: batteryId
                    }
                });

                console.log("API response:", response.data);

                if (Array.isArray(response.data.data)) {
                    // Filtere die Daten, um nur einen Datensatz alle 10 Minuten zu verwenden
                    const filteredData = filterDataByTime(response.data.data);
                    console.log(response.data.data);
                    console.log(filterDataByTime(response.data.data));

                    // Parsen der `batts`-Felder aus JSON-Strings zu Objekten
                    const parsedBatteries = filteredData.map((entry) => JSON.parse(entry.batts));

                    console.log(parsedBatteries);

                    // Extrahiere die benötigten Informationen (Temperatur, Kapazität) aus den geparsten Daten
                    const dates = filteredData.map((entry) => entry.createdAt);

                    // Nehme die `adv`-Daten aus `batts` und parse sie, um Temperatur und Kapazität zu extrahieren
                    const temperature = parsedBatteries.map((battery) => {
                        const macKeys = Object.keys(battery);
                        return parseBuffer(battery[macKeys[0]].adv).ntc1; // Extrahiere Temperatur aus `adv`
                    });

                    const capacity = parsedBatteries.map((battery) => {
                        const macKeys = Object.keys(battery);
                        return parseBuffer(battery[macKeys[0]].adv).remainCap; // Extrahiere Kapazität aus `adv`
                    });

                    setReportData({ dates, temperature, capacity });
                } else {
                    throw new Error("Unexpected API response format");
                }

                setLoading(false);
            } catch (err) {
                console.error("Error fetching report data:", err.message);
                setError("Error fetching report data.");
                setLoading(false);
            }
        };

        fetchReportData();
    }, [batteryId]);


    if (loading) return <p>Loading report...</p>;
    if (error) return <p>{error}</p>;

    const data = {
        labels: reportData.dates,
        datasets: [
            {
                label: "Temperature (°C)",
                data: reportData.temperature,
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                fill: false,
                yAxisID: "y1",
            },
            {
                label: "Capacity (Ah)",
                data: reportData.capacity,
                borderColor: "rgba(54, 162, 235, 1)",
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                fill: false,
                yAxisID: "y2",
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y1: {
                type: "linear",
                position: "left",
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return value + "°C";
                    },
                },
            },
            y2: {
                type: "linear",
                position: "right",
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return value + "Ah";
                    },
                },
            },
        },
    };

    return (
        <div className="home">
            <div className="report-container">
                <div className="battery">
                    <div className="batterie-headline">Report for Battery {batteryId}</div>
                    <div className="card">
                        <div className="card-headline">
                            Temperature
                        </div>
                        <div className="card-body ">
                            <Line data={data} options={options} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatteryReportPage;
