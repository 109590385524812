// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import axios from 'axios'; // axios importieren
import Header from './component/Header';
import Home from './component/Home';
import HomeUser from './component/HomeUser';
import ShowDevice from './component/ShowDevice';
import ConfigDevice from './component/ConfigDevice';
import Login from './component/Login';
import LoginAgent from './component/LoginAgent';
import Register from './component/Register';
import Profile from './component/Profile';
import BatteryReportPage from "./component/BatteryReportPage";
import { AuthProvider, useAuth } from './context/AuthContext';
import { SocketProvider, useSocket } from './context/SocketContext'; // useSocket importieren
import './App.css';

const AppContent = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth(); // useAuth importieren
  const { connectSocket, disconnectSocket } = useSocket(); // useSocket importieren
  const history = useHistory();

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';
  const socketBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/profile/profile`);
        if (response.data.login) {
          setIsLoggedIn(true);
          connectSocket(socketBaseUrl); // Verbindung zum WebSocket herstellen
        } else {
          setIsLoggedIn(false);
          disconnectSocket(); // WebSocket-Verbindung trennen
          history.push('/login');
        }
      } catch (error) {
        setIsLoggedIn(false);
        disconnectSocket(); // WebSocket-Verbindung trennen
        history.push('/login');
      }
    };

    checkAuth();
  }, [history, setIsLoggedIn, connectSocket, disconnectSocket, apiBaseUrl, socketBaseUrl]);

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={domain === 'cloud.liontron.com' ? Login : LoginAgent} />
        <Route exact path="/register" component={Register} />
        <Route exact path={isLoggedIn ? '/dashboard' : '/login'} component={domain === 'cloud.liontron.com' ? HomeUser : Home} />
        <Route path={isLoggedIn ? '/profile' : '/login'} component={Profile} />
        <Route exact path={isLoggedIn ? '/showDevice' : '/login'} component={ShowDevice} />
        <Route path={isLoggedIn ? '/report/:batteryId' : '/login'} component={domain === 'cloud.liontron.com' ? BatteryReportPage : Home} />
        <Route exact path={isLoggedIn ? '/configDevice' : '/login'} component={ConfigDevice} />
      </Switch>
    </>
  );
};

const App = () => (
  <AuthProvider>
    <SocketProvider>
      <Router>
        <AppContent />
      </Router>
    </SocketProvider>
  </AuthProvider>
);

export default App;
