import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfileImageUpload = () => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState('');
    const [message, setMessage] = useState('');
    const [profileImage, setProfileImage] = useState(null);

    const domain = window.location.hostname;
    const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';
    const uploadUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com:8000' : 'https://remote.liontron.com:8000';

    useEffect(() => {
        fetchProfileImage();
    }, []);

    const fetchProfileImage = async () => {
        try {
            const res = await axios.get(`${apiBaseUrl}/profile/profileImage`, {
                withCredentials: true // send cookies with the request
            });
            const { imageUrl } = res.data;
            setProfileImage(`${apiBaseUrl}${imageUrl}`);
        } catch (err) {
            console.error(err);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setMessage('Please select a file.');
            return;
        }

        const formData = new FormData();
        formData.append('profileImage', file);

        try {
            const res = await axios.post(`${uploadUrl}/profile/upload`, formData, {
                withCredentials: true, // send cookies with the request
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage('File uploaded successfully.');
            setProfileImage(`${uploadUrl}${res.data.imageUrl}`);
        } catch (err) {
            console.error(err);
            setMessage('File upload failed.');
        }
    };

    return (
        <div className='imageUpload'>
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="fileInput">
                    {profileImage || preview ? (
                        <img
                            src={preview || profileImage}
                            alt="Profile"
                            style={{ width: '200px', height: 'auto' }}
                        />
                    ) : (
                        <div style={{ width: '200px', height: '200px', border: '1px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Click to select an image
                        </div>
                    )}
                </label>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <button type="submit">Upload</button>
            </form>
        </div>
    );
};

export default ProfileImageUpload;
