// Login.jsx
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useSocket } from '../context/SocketContext';

const Login = () => {
  const [user, setUser] = useState({ uniqueID: '', password: '' });
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const { connectSocket } = useSocket();

  const domain = window.location.hostname;
  const apiBaseUrl = domain === 'cloud.liontron.com' ? 'https://cloud.liontron.com' : 'https://remote.liontron.com';

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn, history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/auth/login`, user);
      console.log(response.data);
      setMsg(response.data.msg);

      if (response.data.login) {
        setIsLoggedIn(true);
        connectSocket(apiBaseUrl);
        history.push('/dashboard');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setMsg('Login failed');
    }
  };

  return (
    <div className="login">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>E-Mail:</label>
          <input type="text" name="uniqueID" value={user.uniqueID} onChange={handleChange} required autoComplete="off"/>
        </div>
        <div>
          <label>Password:</label>
          <input type="password" name="password" value={user.password} onChange={handleChange} required autoComplete="off"/>
        </div>
        <button type="submit">Login</button>
      </form>
      {msg && <p className="info">{msg}</p>}
    </div>
  );
};

export default Login;
